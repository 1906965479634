window.addEventListener("DOMContentLoaded", function () {

  const entryListSearch = document.querySelector(".entry-list-search");
  if (entryListSearch) {
    console.log('entryListSearch');
    const toggle = document.querySelector(".entry-list-search__toggle");
    toggle.addEventListener('click', () => {
      entryListSearch.classList.toggle('active');
    })
  }
})