import GLightbox from 'glightbox';
import Flickity from 'flickity';
import Accordion from 'accordion-js';
import scrollDir from "scrolldir";
import 'iframe-resizer';
import 'particles.js';
import './components/entry-search-widget';

import './ryallax';


// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const hamburger = document.querySelector('.js-hamburger');
    const closeButton = document.querySelector('.js-close');
    const mobileNavigation = document.querySelector('[data-mobile]');
    const mobileArrows = document.querySelectorAll('.mobile-menu__trigger');
    const mobileParentWithSubMenuLink = document.querySelectorAll('.mobile-menu__link.has-sub-menu > a');
    const mobileTitles = document.querySelectorAll('.mobile-menu__sub-title');
    const mobileMenuContent = document.querySelector('.mobile-menu__column');
    const mobileMenuContentUL = document.querySelector('.mobile-menu__column > ul');
    const header = document.querySelector('[data-header]');

	iFrameResize({ log: false }, '#contact-form');

	particlesJS("particles-js", {"particles":{"number":{"value":12,"density":{"enable":true,"value_area":1183.721462448409}},"color":{"value":"#aa6de9"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"","width":100,"height":100}},"opacity":{"value":1,"random":true,"anim":{"enable":false,"speed":1,"opacity_min":0,"sync":false}},"size":{"value":30,"random":true,"anim":{"enable":false,"speed":4,"size_min":0.8,"sync":true}},"line_linked":{"enable":true,"distance":280,"color":"#83c4ff","opacity":1,"width":1},"move":{"enable":true,"speed":1,"direction":"none","random":true,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":600}}},"interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":true,"mode":"bubble"},"onclick":{"enable":true,"mode":"repulse"},"resize":true},"modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},"bubble":{"distance":250,"size":0,"duration":2,"opacity":0,"speed":3},"repulse":{"distance":400,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});
	
    //console.log(mobileParentWithSubMenuLink)
    /* window.addEventListener('scroll', () => {
        body.setAttribute('data-scrolled', 'yes');

        if (window.scrollY > 120) {
            header.classList.add('header--fixed');
        } else {
            header.classList.remove('header--fixed');
        }
    }); */

    scrollDir();


    hamburger.addEventListener('click', (e) => {
        e.preventDefault();
        mobileNavigation.classList.add('active');
        return false;
    });

    closeButton.addEventListener('click', (e) => {
        e.preventDefault();
        mobileNavigation.classList.remove('active');
        return false;
    });

    for (const mobileArrow of mobileArrows) {
        mobileArrow.addEventListener('click', (e) => {
            e.preventDefault();
            const li = mobileArrow.parentNode.parentNode;
            const mobileNav = li.querySelector('.mobile-menu__sub-nav');
            mobileNav.style.height = 'auto';
            const boundingRect = mobileNav.getBoundingClientRect();
            const contentRect = mobileNavigation.getBoundingClientRect();
            if ((boundingRect.height + 95) < contentRect.height) {
                mobileMenuContentUL.style.removeProperty('height');
            } else {
                mobileMenuContentUL.style.height = (boundingRect.height) + 'px';
            }
            mobileNav.classList.add('active');
        });
    }

    for (const mobileParentLink of mobileParentWithSubMenuLink) {
        mobileParentLink.addEventListener('click', (e) => {
            e.preventDefault();
            const mobileMenuTrigger = mobileParentLink.parentNode.querySelector('.mobile-menu__trigger');
            mobileMenuTrigger.click();
        });
    }

    for (const mobileTitle of mobileTitles) {
        mobileTitle.addEventListener('click', (e) => {
            const nav = mobileTitle.parentNode;
            nav.classList.remove('active');
            nav.style.removeProperty('height');
            mobileMenuContentUL.style.removeProperty('height');
        });
    }

    const sliders = document.querySelectorAll('.js-slider');
    if (sliders.length) {
        for (const slider of sliders) {
            const parent = slider.parentNode;
            const flky = new Flickity(slider, {
                autoPlay: false,
                cellAlign: 'center',
                pageDots: false,
                prevNextButtons: false
            });

            const prevBtn = parent.querySelector('.js-left');
            prevBtn.addEventListener('click', (e) => {
                e.preventDefault();
                flky.previous();
                return false;
            });

            const nextBtn = parent.querySelector('.js-right');
            nextBtn.addEventListener('click', (e) => {
                e.preventDefault();
                flky.next();
                return false;
            });

        }
    }


    const accordions = document.querySelectorAll('[data-accordion]');
    if (accordions && accordions.length) {
        for (const accordionDom of accordions) {
            const accordion = new Accordion(accordionDom, {
                openOnInit: [0],
                onOpen: (element) => {
                    const img = element.getAttribute('data-img');
                    if (img) {
                        const container = findAncestor(element, '[data-accordion-container]');
                        if (container) {
                            const imgHolder = container.querySelector('[data-accordion-image]');
                            imgHolder.style.backgroundImage = 'url(' + img + ')';
                        }
                    }
                }
            });
        }
    }

    const categoryDropdowns = document.querySelectorAll('.js-category-dd');
    for (const categoryDropdown of categoryDropdowns) {
        categoryDropdown.addEventListener('change', () => {
            window.location = categoryDropdown.value;
        });
    }


    // glight box
    const lightbox = GLightbox({
        touchNavigation: true,
        autoplayVideos: true,
        svg: {
            close: '<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="28" cy="28" r="27.5" stroke="white"/><path d="M34 22L22 34" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 22L34 34" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        }
    });

    // header dropdown navigations
    const triggers = document.querySelectorAll('[dd-trigger]');
    const headerNav = document.querySelector('.header__nav');
    let openedNavigation = null;

    function updateNavigationPadding() {
        const li = openedNavigation.parentNode.parentNode;
        const headerNav = openedNavigation.parentNode.parentNode.parentNode.parentNode;
        const subNav = openedNavigation.parentNode.parentNode.querySelector('.header__subnav');

        const otherLis = document.querySelectorAll('li.open');
        for (const otherLi of otherLis) {
            otherLi.classList.remove('open');
        }

        li.classList.add('open');
        if (!subNav.classList.contains('header__subnav--full')) {
            const paddingLeft = headerNav.getBoundingClientRect().x;
            subNav.style.paddingLeft = paddingLeft + 'px';
        }
    }

    window.addEventListener('resize', () => {
        if (openedNavigation) {
            updateNavigationPadding();
        }
    })

    openedNavigation = document.querySelector('.active.open [dd-trigger]');
    if (openedNavigation) {
        updateNavigationPadding();
    }

    window.addEventListener('load', () => {
        openedNavigation = document.querySelector('.active.open [dd-trigger]');
        if (openedNavigation) {
            updateNavigationPadding();
        }
    })

    if (triggers.length) {
        for (const trigger of triggers) {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();
                const li = trigger.parentNode.parentNode;
                const megaMenu = li.querySelector('.mega-menu');

                if (li.classList.contains('open')) {
                    li.classList.remove('open');
                    headerNav.classList.remove('header__nav--small-active');
                } else {
                    openedNavigation = trigger;
                    updateNavigationPadding();

                    if (megaMenu) {
                        headerNav.classList.remove('header__nav--small-active');
                    } else {
                        headerNav.classList.add('header__nav--small-active');
                    }

                }
                return false;
            });
        }
    }

});

window.addEventListener("load", () => {

    window.setTimeout(() => {
		

        // fade animations
        const fades = document.querySelectorAll('.fade');
        function checkLoaded() {
            const w = window.innerHeight;
            const offset = 100;
            for (const dom of fades) {
                const rect = dom.getBoundingClientRect();
                if (rect.y < w - offset) {
                    dom.classList.add('active');
                } else {
                    if (parseInt(dom.getAttribute('data-once')) !== 1) {
                        dom.classList.remove('active');
                    }
                }
            }
        }
		const body = document.querySelector('body');
		function addLoadClass() {
			body.classList.add('pg-load');
		}

		addLoadClass();

        if (fades.length) {
            checkLoaded();
            window.addEventListener('scroll', () => {
                checkLoaded()
            });
        }
    }, 500);
});


function bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener) {
        el.addEventListener(eventName, eventHandler, false);
    } else if (el.attachEvent) {
        el.attachEvent('on' + eventName, eventHandler);
    }
}

function findAncestor(el, sel) {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, sel)));
    return el;
}