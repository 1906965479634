document.addEventListener('DOMContentLoaded', function () {
  const ryallax = document.querySelectorAll('.ryallax');
  if (ryallax) {
    ryallax.forEach((bg) => {
      if (bg.nodeName === "IMG") {
        moveIMG(bg, '');
      } else {
        moveBG(bg, '');
      }
    });
    window.addEventListener('scroll', (a) => {
      // console.log(a);
    });
  }
  function handler(e) {
    var isTouchPad = e.wheelDeltaY
      ? e.wheelDeltaY === -3 * e.deltaY
      : e.deltaMode === 0;
    const type = isTouchPad ? 'isTouchPad' : 'isMouse';
    ryallax.forEach((bg) => {
      if (bg.nodeName === "IMG") {
        moveIMG(bg, type);
      } else {
        moveBG(bg, type);
      }

      // const inertial = setInterval(() => {
      // }, 100);
      // setTimeout(() => {
      //   clearInterval(inertial);
      // }, 100);
    });
  }
  document.addEventListener('scroll', handler, false);
  document.addEventListener('wheel', handler, false);
  document.addEventListener('DOMMouseScroll', handler, false);
});
function moveBG(bg, type) {
  if (type == 'isMouse') {
    bg.classList.add('smooth');
  } else {
    bg.classList.remove('smooth');
  }
  const BgCoordinates = bg.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const ryallax_range = windowHeight + BgCoordinates.height;
  const relative_top = windowHeight - BgCoordinates.top;
  let percent = ((ryallax_range - relative_top) / ryallax_range) * 100;

  if (percent > 100) {
    percent = 100;
  }
  if (percent < 0) {
    percent = 0;
  }
  bg.style.backgroundPositionY = percent + '%';
}
function moveIMG(img, type) {
  const scrollPos = window.pageYOffset;
  const scrollableHeight =
    document.querySelector('body').scrollHeight - window.innerHeight;
  let percent = scrollPos / scrollableHeight;
  if (percent > 100) {
    percent = 1;
  }
  if (percent < 0) {
    percent = 0;
  }
  percent = percent * 150;
  img.style.transform = "translateY(" + percent + "%)";
}
